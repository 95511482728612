:root {
  /* hexa  */
  --gray: #f7f7f7;
  --white: #ffffff;
  --delete: #e74c3c;
  --information: #115293;
  --warn: #e1c848;
  --success: #2bd628;
  --gelo: #f7f7f7;
  --excelcolor: #217146;
  --waitingColor: #eead2d;

  --primary: #07ee04;
  --primary2: #078c15;
  --tertiary: #82ca9d;

  --colorcard1: #29e550;
  --colorcard2: #07d215;
  --colorcard3: #07bf15;
  --colorcard4: #079815;

  /* gba */
  --black_rgb: 0, 0, 0;
  --white_rgb: 255, 255, 255;
  --delete_rgb: 231, 76, 60;
  --warn_rgb: 225, 200, 72;
  --excelcolor_rgb: 33, 113, 70;

  --primary_rgb: 7, 238, 4;
  --primary2_rgb: 7, 140, 21;
  --tertiary_rgb: 130, 202, 157;

  --colorcard1_rgb: 41, 229, 80;
  --colorcard2_rgb: 7, 229, 21;
  --colorcard3_rgb: 7, 210, 21;
  --colorcard4_rgb: 7, 191, 21;
}

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
